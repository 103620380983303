import React from 'react';
import './box.scss';

export default class Box extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="box text-center">{this.props.children}</div>
      </div>
    );
  }
}
