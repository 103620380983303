import React from 'react';
import LoadingComponents from '../loaders/loadingComponents';

export class DefaultButton extends React.Component {
  render() {
    let { loading, labelloading } = this.props;
    return (
      <button
        style={this.props.style ? this.props.style : { borderRadius: '10px', padding: '10px' }}
        className={this.props.className ? this.props.className : `btn btn-primary`}
        data-toggle={this.props.datatoggle}
        aria-haspopup={this.props.ariahaspopup}
        aria-expanded={this.props.ariaexpanded}
        onClick={this.props.onClick}
        type={this.props.type}
        disabled={loading ? true : this.props.disabled}
      >
        {this.props.children}
        {loading ? (
          <>
            <LoadingComponents /> {labelloading ? labelloading : this.props.label}{' '}
          </>
        ) : (
          this.props.label
        )}
      </button>
    );
  }
}

export class ButtomSuccess extends React.Component {
  render() {
    let { loading, labelloading } = this.props;
    return (
      <button
        style={{ borderRadius: '10px', padding: '10px' }}
        disabled={loading ? true : this.props.disabled}
        className={this.props.className ? this.props.className : 'btn btn-success '}
        onClick={this.props.onClick}
        type={this.props.type}
      >
        {this.props.children}
        {loading ? (
          <>
            <LoadingComponents /> {labelloading ? labelloading : this.props.label}{' '}
          </>
        ) : (
          <>{this.props.label}</>
        )}
      </button>
    );
  }
}

export class SecondaryButton extends React.Component {
  render() {
    let { loading, labelloading } = this.props;
    return (
      <button
        style={{ borderRadius: '10px', padding: '10px' }}
        className={'btn btn-outline-dark   ' + this.props.className}
        disabled={loading ? true : this.props.disabled}
        onClick={this.props.onClick}
        type={this.props.type}
      >
        {this.props.children}
        {loading ? (
          <>
            <LoadingComponents /> {labelloading ? labelloading : this.props.label}{' '}
          </>
        ) : (
          <>{this.props.label}</>
        )}
      </button>
    );
  }
}
