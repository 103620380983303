import axios from 'axios';
import { getToken, getRefreshToken, logout, login } from './auth';
//Setar a base url
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

//Adiciona o token em todas as requisições, caso não deva usar, use o axios padrão
api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

//Adiciona um interceptor nas requisições para ver houve um erro do back
api.interceptors.response.use(
  function (response) {
    //Qualquer status que esteja dentro dos "200" vai entrar nessa função
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      if (error.response.data.erro !== 'Usuário ou senha inválidos!') {
        if (error.response && error.response.data.erro.indexOf('jwt expired') > -1) {
          api.post('/refresh', { refresh: getRefreshToken() }).then(
            res => {
              login({ token: res.data.token });
            },
            err => {
              // se não tiver um token valido leva o user para a tela de login
              logout();
            }
          );
        } else {
          logout();
        }
      }
    }
    // Outros status caem aqui
    return Promise.reject(error);
  }
);

export default api;
