import React from 'react';
import { Form, Field } from 'react-final-form';
import { setIn } from 'final-form';
import * as yup from 'yup';
import { withRouter } from 'react-router';
import DefaultButton from '../../components/buttons/defaultButton';
import './login.scss';
import TextField from '../../components/fields/textField';
// import CheckBox from '../../components/checkBox';
import server from '../../services/api';
import { login } from '../../services/auth';
import * as _ from 'lodash';
import { withToastManager } from 'react-toast-notifications';

class LoginPage extends React.Component {
  schema = yup.object().shape({
    email: yup.string().email('Email deve ser válido').required('Email é obrigatório'),
    password: yup
      .string()
      .min(8, 'Senha deve possuir no mínimo 8 caracteres')
      .max(15, 'Senha deve conter no máximo 15 caracteres')
      .required('Senha é obrigatória'),
  });

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      res: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validate = async values => {
    try {
      await this.schema.validate(values, { abortEarly: false });
    } catch (err) {
      const errors = err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});

      return errors;
    }
  };

  handleSubmit(object, event) {
    const newObject = this.schema.cast(object);

    server.post('/login', newObject).then(
      res => {
        login({
          token: res.data.token,
          refreshToken: res.data.refreshToken,
          ...res.data.usuario,
        });
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.from &&
          this.props.location.state.from.pathname
        ) {
          this.props.history.push(this.props.location.state.from.pathname);
        } else {
          this.props.history.push('/selecionar_entidade');
        }
      },
      err => {
        this.props.toastManager.add(
          _.get(err, 'response.status', 500) >= 500 ? 'Desculpa estamos com problemas' : err.response.data.erro,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      }
    );
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <>
        {/* <div className={`corpo_pagina`}>
                        <div className="borda_superior" />
                        <div className="formulario_total">
                            <div className="form">
                                <div className="logo">
                                    <img className="logoImage" src="https://www.essentjus.com.br/img/repositorio/5b2d40449dd79.png" />
                                </div>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    validate={this.validate}
                                    render={({ handleSubmit, form }) => (
                                        < form onSubmit={handleSubmit}>
                                            <Field name="email">
                                                {({ input, meta }) => (
                                                    <div className="campos">
                                                        <TextField label="Usuário" placeholder="Seu usuário" input={input} meta={meta} />
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="password">
                                                {({ input, meta }) => (
                                                    <div className="campos">
                                                        <TextField label="Senha" placeholder="Sua senha" input={input} meta={meta} type="password" />
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name="remember">
                                                {({ input, meta }) => (
                                                    <div className="campos">
                                                        <CheckBox label='Continuar Logado' input={input} meta={meta} />
                                                    </div>
                                                )}
                                            </Field>
                                            <div className="field_btn_continue">
                                                <div className="btn_continue">
                                                    <DefaultButton label="Continuar" />

                                                </div>
                                            </div>
                                        </form>
                                    )}
                                /> */}
        {/* <div className="forget_my_password_btn">
                            <span>Esqueci minha senha</span>
                        </div> */}
        {/* ainda não esta devidamente feito */}
        {/* </div>
                        </div>
                    </div> */}

        <section className="container container-login">
          <div className="borda_superior" />
          <div className="col col-lg-6 col-md-8 col-xl-5 m-auto">
            <div className="card card-login">
              <Form
                onSubmit={this.handleSubmit}
                validate={this.validate}
                render={({ handleSubmit, form }) => (
                  <form onSubmit={handleSubmit} className="text-center">
                    <div className="mb-2 p-2 pb-5">
                      <img
                        src="https://www.essentjus.com.br/img/repositorio/5b2d40449dd79.png"
                        alt="logo"
                        className="logo-jus"
                      />
                    </div>
                    <Field name="email">
                      {({ input, meta }) => (
                        <div className="form-group mb-4 text-left">
                          <TextField label="Usuário" placeholder="Seu usuário" input={input} meta={meta} type="email" />
                        </div>
                      )}
                    </Field>
                    <Field name="password">
                      {({ input, meta }) => (
                        <div className="form-group mb-4 text-left">
                          <TextField label="Senha" placeholder="Sua senha" input={input} meta={meta} type="password" />
                        </div>
                      )}
                    </Field>
                    <label
                      style={{ color: '#758098', fontSize: '0.7rem', bottom: '0px', position: 'absolute', right: 40 }}
                    >
                      v. {process.env.REACT_APP_VERSION}
                    </label>
                    {/* <Field name="remember">
                                                {({ input, meta }) => (
                                                    <div className="form-group mb-4 text-left">
                                                        <CheckBox label='Continuar Logado' input={input} meta={meta} />
                                                    </div>
                                                )}
                                            </Field> */}
                    <div className="form-group text-right mt-md-5 mt-4">
                      <DefaultButton label="Continuar" />
                    </div>
                  </form>
                )}
              />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(withToastManager(LoginPage));
