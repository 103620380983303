import React from 'react';

export default class DefaultButton extends React.Component {
  render() {
    return (
      <div>
        <button style={{ borderRadius: '10px', padding: '10px' }} className={'btn-primary'} {...this.props}>
          {this.props.label}
        </button>
      </div>
    );
  }
}
