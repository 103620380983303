import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './views/login/loginPage';

import Loading from './components/loaders/Loading';
import loadable from 'react-loadable';
import { isAuthenticated } from './services/auth';

import { ToastProvider } from 'react-toast-notifications';

import RecuperSenha from './views/settings/RecuperarSenha';

const App = loadable({
  loader: () => import('./App'),
  loading: Loading,
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);

const RenderLogin = () =>
  isAuthenticated() ? <Redirect to={{ pathname: '/', state: { from: '/login' } }} /> : <Login />;

const Routes = () => (
  <ToastProvider>
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={() => <RenderLogin />} />
        <Route exact path="/recuperar_senha/:token" component={() => <RecuperSenha />} />
        <PrivateRoute path="/*" component={() => <App />} />
      </Switch>
    </BrowserRouter>
  </ToastProvider>
);

export default Routes;
