import React from 'react';
import '../style.scss';

export default class TextField extends React.Component {
  render() {
    const meta = this.props.meta;
    return (
      <div className={'divField'}>
        <div className={`pb-3 labelTextField ${meta.touched && meta.error ? 'labelError' : null}`}>
          {this.props.label || 'Label não definida'}
        </div>
        <input
          autoComplete="off"
          className={`inputTextField ${meta.touched && meta.error ? 'inputError' : null}`}
          type={this.props.type}
          {...this.props.input}
          placeholder={this.props.placeholder ? this.props.placeholder : this.props.label}
        />
      </div>
    );
  }
}
