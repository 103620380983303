import React from 'react';
import '../style.scss';
import LoadingComponents from '../loaders/loadingComponents';

export default class InputComponet extends React.Component {
  render() {
    let { loading, labelLoading } = this.props;
    return (
      <>
        <input
          autoComplete="off"
          type={this.props.type}
          className={'inputTextField ' + (this.props.erro ? ' is-invalid' : ' ')}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          value={this.props.value}
          name={this.props.name}
          maxLength={this.props.maxLength}
          disabled={loading ? loading : this.props.disabled}
          onKeyPress={this.props.onKeyPress}
        />
        {loading ? (
          <div className="mt-0" style={{ height: 0 }}>
            <LoadingComponents /> {labelLoading}{' '}
          </div>
        ) : (
          <></>
        )}
        <div className="invalid-feedback mt-0" style={{ height: 0 }}>
          {this.props.labelErro}
        </div>
      </>
    );
  }
}
