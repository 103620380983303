// Funções que retornam dados do token e do login do usuário
// responsável por fazer o login e logout também
export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const NOME = 'nome';
export const ID = 'id';

export const ENTIDADE_ID = 'entidade_id';
export const ENTIDADE_CNPJ = 'entidade_cnpj';
export const ENTIDADE_NOME = 'entidade_nome';
export const ENTIDADE_PARTIDO = 'entidade_partido';
export const ENTIDADE_TIPO_USUARIO = 'entidade_tipo_usuario';
export const ENTIDADE_URL_IMAGEM = 'entidade_url_imagem';

export const CANDIDATO = 'candidato';
export const CONTADOR = 'contador';
export const CONTADOR_ADMINISTRADOR = 'contador_administrador';
export const OBSERVADOR = 'observador';
export const SUPERADMIN = 'superadmin';
export const TESOUREIRO = 'tesoureiro';
export const USAR_CERTIFICADO = 'usar_certificado';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const login = obj => {
  localStorage.setItem(ID, obj.id);
  localStorage.setItem(NOME, obj.nome);
  localStorage.setItem(TOKEN_KEY, obj.token);
  localStorage.setItem(SUPERADMIN, obj.superadmin);
  if (obj.refreshToken) localStorage.setItem(REFRESH_TOKEN_KEY, obj.refreshToken);
};

export const entidade = obj => {
  localStorage.setItem(ENTIDADE_ID, obj.entidade_id);
  localStorage.setItem(ENTIDADE_NOME, obj.entidade_nome);
  localStorage.setItem(ENTIDADE_CNPJ, obj.entidade_cnpj);
  localStorage.setItem(ENTIDADE_PARTIDO, obj.entidade_partido);
  localStorage.setItem(ENTIDADE_URL_IMAGEM, obj.entidade_url_imagem);
  localStorage.setItem(ENTIDADE_TIPO_USUARIO, obj.entidade_tipo_usuario);
};

export const permissoes = obj => {
  localStorage.setItem(CANDIDATO, obj.candidato);
  localStorage.setItem(CONTADOR, obj.contador);
  localStorage.setItem(CONTADOR_ADMINISTRADOR, obj.contador_administrador);
  localStorage.setItem(OBSERVADOR, obj.observador);
  localStorage.setItem(SUPERADMIN, obj.superadmin);
  localStorage.setItem(TESOUREIRO, obj.tesoureiro);
  localStorage.setItem(USAR_CERTIFICADO, obj.usar_certificado);
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/login';
};
