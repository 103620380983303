import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import server from '../../services/api';
import Label from '../texto/Labels';
import Input from '../inputs/InputComponent';
import { DefaultButton } from '../buttons/ComponentButtons';
import Loader from '../loaders/Loader';
import '../../views/login/login.scss';
import { logout } from '../../services/auth';
import { withToastManager } from 'react-toast-notifications';
import * as _ from 'lodash';

class FomrRecuperarSenha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        senha: '',
      },
      nome: '...',
      id: null,
      controller: null,
      senha: '',
      confSenha: '',
      errors: {},
      passMsg: '',
      loadingStatus: true,
      msgErro: '',
    };
    this.getStatus = this.getStatus.bind(this);

    this.verificarSenha = this.verificarSenha.bind(this);

    this.handleStates = this.handleStates.bind(this);

    this.concluirRegistro = this.concluirRegistro.bind(this);
  }

  componentDidMount() {
    this.getStatus();

    this.setState({
      chave: this.props.chave,
      periodo: this.props.periodo,
    });
  }

  getStatus() {
    this.setState({ loadingStatus: true });
    server.post(`/verify_forgot_password_token`, { token: this.props.token }).then(
      res => {
        this.setState({
          nome: res.data.user.nome,
          id: res.data.user.id,
          loadingStatus: false,
        });
      },
      err => {
        this.props.toastManager.add(
          _.get(err, 'response.status', 500) >= 500 ? 'Desculpa estamos com problemas' : err.response.data.erro,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
        this.setState({ msgErro: err.response.data.erro, loadingStatus: false });
      }
    );
  }

  concluirRegistro(event) {
    event.preventDefault();
    this.setState({ buttonLoading: true });

    let data = {
      id: this.state.id,
      token: this.props.token,
      newPassword: this.state.senha,
      confirmPassword: this.state.confSenha,
    };

    server.post('/change_password', data).then(
      res => {
        this.props.toastManager.add(res.data.msg, {
          appearance: 'success',
          autoDismiss: true,
        });
        this.setState({
          buttonLoading: false,
          controller: true,
        });
      },
      err => {
        this.props.toastManager.add(
          _.get(err, 'response.status', 500) >= 500 ? 'Desculpa estamos com problemas' : err.response.data.erro,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
        this.setState({ buttonLoading: false });
      }
    );
  }

  verificarSenha() {
    let err = this.state.erros || {};
    if (this.state.senha.length < 6) {
      err['senha'] = true;
    } else {
      if (this.state.confSenha.length !== this.state.senha.length) {
        err['senha'] = true;
        delete this.state.form.senha;
      }
    }
    return Object.keys(err).length > 0;
  }

  handleStates(obj, value) {
    let tipo = obj.target ? obj.target.name : obj.props.name;
    let valor = obj.target ? obj.target.value : value;

    let err = this.state.errors;
    let msg;
    if (tipo === 'senha') {
      if (valor.length <= 5 || valor.length === 0) {
        err[tipo] = true;
        msg = 'Preencha com 6 dígitos.';
      } else {
        delete err[tipo];
      }
    } else {
      if (valor !== this.state.senha) {
        err[tipo] = true;
        msg = 'Senha incompatível.';
      } else {
        delete err[tipo];
      }
    }

    this.setState(oldState => ({
      [tipo]: valor,
      errors: err,
      passMsg: msg,
    }));
  }

  render() {
    return (
      <>
        {!this.state.loadingStatus ? (
          <section className="container container-login justify-content-center">
            <div className="borda_superior" />
            <div className="col col-lg-6 col-md-8 col-xl-5">
              <div className="card card-login justify-content-center">
                <img className="col align-self-center mt-3 mb-5" src="/logo.png" alt="logo" style={{ maxWidth: 250 }} />
                {this.state.id ? (
                  this.state.controller ? (
                    <>
                      <div className="pb-5">
                        Senha cadastrada com sucesso, clique no botão abaixo para acessar o sistema
                      </div>
                      <div className="text-center">
                        <Link to="/login">
                          <DefaultButton
                            type="button"
                            label="Fazer Login"
                            onClick={() => {
                              logout();
                            }}
                          />
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col pb-5 m-auto  text-center justify-content-center">
                        Bem-vindo <b>{this.state.nome}</b>, agora vamos alterar a sua senha de acesso ao sistema
                      </div>
                      <form onSubmit={this.concluirRegistro}>
                        <div className="form-row ">
                          <div className="form-group col-12 mb-4 text-left">
                            <Label hasLabel="true" label="Senha"></Label>
                            <Input
                              placeholder="Senha"
                              name="senha"
                              value={this.state.senha}
                              onChange={e => this.handleStates(e, null)}
                              labelErro={this.state.passMsg}
                              erro={this.state.errors.senha}
                              type="password"
                            />
                          </div>
                          <div className="form-group mb-4 col-12 text-left">
                            <Label hasLabel="true" label="Confirme a sua senha"></Label>
                            <Input
                              placeholder="Confirmação de senha"
                              name="confSenha"
                              value={this.state.confSenha}
                              onChange={e => this.handleStates(e, null)}
                              labelErro={this.state.passMsg}
                              erro={this.state.errors.confSenha}
                              disabled={this.state.senha.length < 6 ? true : false}
                              type="password"
                            />
                          </div>
                        </div>

                        <div className="text-right form-group m-auto">
                          <DefaultButton
                            type="submit"
                            label={'Finalizar'}
                            disabled={this.verificarSenha()}
                            loading={this.state.buttonLoading}
                            labelloading={'Finalizando'}
                          />
                        </div>
                      </form>
                    </>
                  )
                ) : (
                  <>
                    <div className="col pb-5 m-auto  text-center justify-content-center">{this.state.msgErro}</div>
                    <div className="text-center">
                      <Link to="/login">
                        <DefaultButton
                          type="button"
                          label="Fazer Login"
                          onClick={() => {
                            logout();
                          }}
                        />
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            <footer className="fixed-bottom text-right mb-sm-4 mb-2" style={{ zIndex: -100, right: '4%' }}>
              <img src="/logo_dash.jpg" alt="logo" className="logo-rodape" />
            </footer>
          </section>
        ) : (
          <div className="col-12 m-auto text-center mt-5 h-100" style={{ top: 300 }}>
            <Loader />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withToastManager(FomrRecuperarSenha));
