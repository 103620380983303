import React from 'react';
import Box from '../container/Box';

export default class Loading extends React.Component {
  render() {
    let { props } = this;
    if (props.error) {
      return (
        <div>
          Ops! Ocorreu um erro{' '}
          <button className="btn btn-default" onClick={props.retry}>
            Tente novamente
          </button>
        </div>
      );
    } else if (props.pastDelay) {
      return (
        <Box>
          <div className="spinner-border m-5" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Box>
      );
    } else return null;
  }
}
