import React from 'react';
import { withRouter } from 'react-router-dom';
import FormRecupearSenha from '../../components/forms/RecuperarSenha';

class RegistroUsuario extends React.Component {
  render() {
    return <FormRecupearSenha token={this.props.match.params.token} />;
  }
}

export default withRouter(RegistroUsuario);
